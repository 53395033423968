.display-block {
  display: block;

  &.toggled {
    display: none;
  }
}

.display-block-create {
  display: block;

  &.toggled {
    display: none;
  }
}
