@import "../scss/variables";
@import "../scss/navigation";
@import "../scss/tables";
@import "../scss/mediaQueries";
@import "../scss/playlist";
@import "../scss/_switch.scss";
@import "../scss/checkBox";

@import "./variables";

.icon.input-icon,
.info-select {
  background-color: $active-menu-color;
  color: #fff;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.btn.div-special {
  background-color: $active-menu-color;
  padding: 14px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 46px;
  height: 50px;
}

.input-special {
  .selection {
    width: calc(100% - 48px);
  }
}

//reussable classes & tags
body {
  background-color: $background-color;
}

button:disabled {
  cursor: not-allowed;
}

h2 {
  font-size: 1.8rem;
}

label {
  margin-bottom: 0;
}

.alt-color-text {
  color: $text-color;
}

.athlete-form {
  border: 1px solid $background-color !important;

  &:hover {
    border-color: $active-menu-color !important;
  }
}

.blue-link {
  color: $active-menu-color;
}

.chart-icon,
.no-data-icon,
.timer-icon {
  font-size: $nav-height*0.5;
}

.exercise-form {
  border: 1px solid $text-color-second;

  &:hover {
    border: 1px solid $active-menu-color !important;
  }
}

.input-text {
  border-radius: 4px;
  background-color: $table-accent-bg !important;
}

.hidden-error,
.red {
  margin-bottom: 10px;
}

.pointer {
  cursor: pointer;

  &:hover {
    color: $hover;

    * {
      color: $hover;
    }
  }
}

.sync.ui.icon.input {
  height: 40px;
  background: #fff !important;

  .input-text {
    height: 40px;
    font-size: 1rem !important;
    background: #fff !important;
  }
}

//bootstrap classes
.btn {
  font-weight: 500;
}

.btn-lg {
  padding: 0.75rem 1rem;
}

.card {
  box-shadow: $box-shadow;
  border-collapse: collapse;
}

.form-control-register:focus,
select:focus {
  border: 1px solid $hover !important;
}

.nav-link {
  border: none;
  border-bottom: 4px solid transparent !important;

  &:hover {
    border: none;
    border-bottom: 4px solid $background-color !important;
  }

  &.active {
    border: none;
    border-bottom: 4px solid $active-menu-color !important;
    color: $active-menu-color !important;
  }
}

.row.m-2.m-sm-3.m-md-4.m-lg-5 {
}

.small {
  font-size: 90%;
  color: $text-color-second;
  margin-bottom: 6px;
}

tr:hover {
  background-color: $background-color !important;
}

//semantic-ui overwrite classes (modals)

.pop-up {
  position: relative;
  z-index: 1;
  width: 300px;
  background: white;
  border: 1px solid $text-color-second;
  border-radius: 7px;
}

.pushed-menu {
  left: -70px !important;

  &.exercises {
    left: -140px !important;
  }
}

.row .ui.form .field > label {
  color: $text-color;
}

.sequence-wrapper {
  border: 1px solid $text-color-second;

  &.clickable {
    border-right: none !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  &:hover {
    border: 1px solid $active-menu-color;
  }
}

.ui.button {
  background-color: #ddd;
  color: #000;

  &.primary {
    background-color: $active-menu-color;
    color: #fff;

    &:focus {
      background-color: $active-menu-color;
    }
  }
}

.ui.accordion .title:not(.ui) {
  padding: 0 !important;
}

.ui.modal,
.ui.small.modal {
  height: auto;
}

//general select\dropdown
.ui.selection.dropdown {
  border-color: $background-color !important;

  .menu.visible {
    max-height: 200px;
    border-color: $active-menu-color !important;

    &:hover {
      border-color: $active-menu-color !important;
    }

    .item {
      &:hover {
        color: $active-menu-color !important;
        background-color: #fff;
      }
    }
  }

  &.active {
    border-color: $active-menu-color !important;

    &:hover {
      border-color: $active-menu-color !important;
    }
  }

  &:hover {
    border-color: $active-menu-color !important;
  }
}
