//dimensions
$nav-height: 76px;
$display: calc(#{$nav-height} * 3);

//the main colors
$active-menu-color: #36f;
$background-color: #ffffff;
$hover: #598bff;
$table-accent-bg: #f7f9fc;
$text-color: #586082;
$text-color-second: #8f9bb3;

//bootstrap class modifications

// $btn-padding-y: 1rem;
$box-shadow: 0 0.5rem 1rem 0 rgba(44, 51, 73, 0.1);
$card-border-color: $background-color;
$font-weight-bold: 500;
$gray-300: $background-color;
$input-border-color: $background-color;
$input-height-lg: calc(2em + 1rem + 2px);
$input-color: $text-color-second !default;
$table-cell-padding: 0.5rem 0.5rem 0.5rem 0.5rem;
$input-btn-padding-y: 0.6875rem !default;
$input-btn-padding-x: 1.125rem !default;

//bootstrap colors
$gray-600: #d3d3d3 !default;
$green: #00d68f !default;
$red: #ff3d71 !default;
$yellow: #fa0 !default;
