.app-container {
  background-color: $background-color;
  width: 100%;
  min-height: 100vh;

  .navbar {
    height: $nav-height;
    z-index: 5;

    .hamburger-menu {
      i {
        font-size: 24px;
        color: $text-color-second;
      }
    }

    .menu {
      position: absolute;
      top: $nav-height;
      right: 0;
      background-color: #fff;
    }
  }

  .main {
    background-color: $background-color;
    width: 100%;

    .left-menu {
      background-color: #fff;
      position: fixed;
      height: 100vh;
      bottom: 0;
      top: $nav-height;
      left: 0;
      z-index: 2;
      width: $nav-height;

      .left-menu-item {
        cursor: pointer;
        background-color: #fff;
        text-decoration: none;
        color: $text-color;
        box-sizing: border-box;
        justify-content: center;
        border-left: 4px solid #fff;

        i {
          font-size: 16px;
        }

        p {
          display: none;
        }

        &.current-page {
          color: $active-menu-color;
          border-left: 4px solid $active-menu-color;

          p {
            color: $active-menu-color;
          }

          &:hover {
            border-color: $hover;

            p {
              color: $hover;
            }
          }
        }

        &:hover {
          i,
          p {
            color: $hover;
          }
        }
      }

      &.displayed {
        .left-menu-item {
          &.current-page {
            border-left: 4px solid #fff;
          }
        }
      }
    }

    .nav-integration {
      &.content {
        margin-left: $nav-height;
        padding-top: $nav-height;
        //padding-bottom: $nav-height;
        width: calc(100% - #{$nav-height});
        min-height: 100vh;

        .footer {
          position: fixed;
          bottom: 0;
          right: 0;
          left: $nav-height;
          height: $nav-height;

          a {
            &:hover {
              transition: all 0.5s;
              color: $active-menu-color;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 800px) {
  .app-container {
    .main {
      .left-menu {
        &.displayed {
          width: $display;

          .left-menu-item {
            justify-content: flex-start;

            i {
              font-size: 24px;
            }

            p {
              display: block;
            }
          }
        }
      }

      .nav-integration {
        &.content {
          > .displayed {
            margin-left: calc(#{$display} - #{$nav-height});
            width: calc(100% - (#{$display} - #{$nav-height}));

            .footer {
              left: $display;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .app-container {
    .main {
      .left-menu {
        display: none !important;

        &.displayed {
          width: 100%;
          display: flex !important;

          .left-menu-item {
            i {
              font-size: 24px;
            }

            p {
              display: block;
              font-size: 16px;
              text-transform: uppercase;
              font-weight: bold;
            }

            &:hover {
              color: $active-menu-color;

              p {
                color: $active-menu-color;
              }
            }
          }
        }
      }

      .nav-integration {
        &.content {
          margin-left: 0;
          width: 100%;

          .footer {
            left: 0;
          }
        }
      }
    }
  }
}
