@use "@angular/material" as mat;
@import "@angular/material/theming";
@include mat.core();

// Custom Yellow Palette for Primary
$my-yellow-palette: (
        50:  #FFF9E3,
        100: #FFF2B3,
        200: #FFE912,
        300: #FFE87B,
        400: #FFE56A,
        500: #FFE512, // Your custom yellow
        600: #FFDB12,
        700: #FFCC00,
        800: #FFB700,
        900: #FFA300,
        A100: #FFFFB0,
        A200: #FFFF6A,
        A400: #FFFF31,
        A700: #FFF500,
        contrast: (
                50: $dark-primary-text,
                100: $dark-primary-text,
                200: $dark-primary-text,
                300: $dark-primary-text,
                400: $dark-primary-text,
                500: $dark-primary-text,
                600: $dark-primary-text,
                700: $dark-primary-text,
                800: $dark-primary-text,
                900: $dark-primary-text,
                A100: $dark-primary-text,
                A200: $dark-primary-text,
                A400: $dark-primary-text,
                A700: $dark-primary-text,
        )
);

$my-theme-primary: mat.define-palette($my-yellow-palette);
$my-theme-accent: mat.define-palette(mat.$grey-palette, A100, A400, A700);
$my-theme-warn: mat.define-palette(mat.$red-palette);

$my-theme: mat.define-light-theme(
                $my-theme-primary,
                $my-theme-accent,
                $my-theme-warn
);
@include mat.all-component-themes($my-theme);

:root {
  --color-primary: #FFE512;
  --color-secondary: #3f3f3f;
  --color-text: #000000;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

@import "semantic-ui-css/semantic.min.css";
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500&display=swap");
@import "./assets/styles/scss/main";
@import "material-icons/iconfont/material-icons.scss";
@import "node_modules/bootstrap/scss/bootstrap";


@font-face {
  font-family: "tex_gyre_herosregular";
  src: url("./assets/styles/css/fonts/texgyreheros-regular-webfont.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "tex_gyre_herosregular";
  src: url("./assets/styles/css/fonts/texgyreheros-bold-webfont.woff")
    format("woff");
  font-weight: bold;
  font-style: normal;
}

body,
h1,
h2,
h3,
h4,
h5,
p,
a {
  font-family: "Roboto", sans-serif;
  color: #000;
  letter-spacing: 0.5px;
}

html {
  height: unset !important;
}

body {
  height: 100vh !important;
}

.mat-expansion-panel-header-description,
.mat-expansion-panel-header-title {
  flex-basis: 0;
}

.mat-expansion-panel-header-description {
  display: flex;
  justify-content: flex-end;
}

// =============gabrielct begin

// ==================gabrielct end
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

.disabled-custom {
  pointer-events: none;
  opacity: 0.5;
}

.disabled-custom:hover {
  cursor: not-allowed;
}

.error-message {
  position: absolute;
  top: 25px;
  left: 0;
  width: 100%;
  font-size: 0.8rem;
  color: #f44336;
}

.profile-picture {
  width: 38px;
  height: 38px;
  background-image: url("assets/images/no_avatar.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  border: 1px solid #ffffff;
}

.image-upload {
  visibility: hidden;
  position: absolute;
  overflow: hidden;
  width: 0;
  height: 0;
  border: none;
  margin: 0;
  padding: 0;
}

.location-text {
  font-size: smaller;
}

.account-text {
  font-size: medium;
}

.no-outline {
  outline: none;
}

.athlete-image {
  width: 100px;
  height: auto;
}

.mdc-notched-outline__notch {
  border-right-width: 0px !important;
}

.mat-mdc-unelevated-button>.mat-icon, .mat-mdc-raised-button>.mat-icon, .mat-mdc-outlined-button>.mat-icon
{
    margin: 0 !important;
  width: auto !important;
}

label {
  margin-bottom: 0;
}



.mat-step-header .mat-step-icon {
  color: white;
}

.mat-step-header .mat-step-icon.mat-step-icon-selected
{
  color: black;
}

.color-primary {
  color: mat.get-color-from-palette($my-theme-primary);

  &:hover {
    color: mat.get-color-from-palette($my-theme-primary, A200);
  }

  &:active {
    color: mat.get-color-from-palette($my-theme-primary, A400);
  }

  &:focus {
    color: mat.get-color-from-palette($my-theme-primary, A700);
  }
}

.bg-primary {
  background-color: mat.get-color-from-palette($my-theme-primary) !important;
}


.color-secondary {
  color: mat.get-color-from-palette($my-theme-accent);

  &:hover {
    color: mat.get-color-from-palette($my-theme-accent, A200);
  }

  &:active {
    color: mat.get-color-from-palette($my-theme-accent, A400);
  }

  &:focus {
    color: mat.get-color-from-palette($my-theme-accent, A700);
  }
}

.bg-secondary {
  background-color: mat.get-color-from-palette($my-theme-accent);

}
.mat-horizontal-content-container {
  padding: 24px !important;
}

.mat-horizontal-stepper-header-container {
 position: sticky;
  top: 0;
  z-index: 100;
  background: white;
  border-bottom: 1px solid #ddd;
}

@media only screen and (max-width: 1024px) {
  .mat-horizontal-stepper-header-container {
    flex-direction: column;
    justify-content: start;
    gap: 0.75rem;
  }
}


.mdc-floating-label {
  color: black !important;
}

.z-full {
  z-index: 99999;
}

.custom-form-field {
  display: flex;
  flex-direction: column;
  gap: 8px;

}
.custom-form-field *:focus-visible {
  outline: 1px solid #F5DC11;
}

.custom-form-field * {
  transition: all 0.3s ease;
}

.custom-form-field input:hover {
  border: 1px solid #F5DC11;
  outline: 1px solid #F5DC11;
}

.custom-form-field label {
  color: #C0C0C5;
  font-weight: 500;
}

.custom-form-field input, .custom-form-field input:focus
{
  background: #272729;
  border: 1px solid #58585B;
  padding: 16px;
  border-radius: 16px;
  color: #838388;
  font-weight: 500;
  font-size: 16px;
}

.animated-custom-error {
  color: #f44336;
  margin-top: 0.2rem;
  font-weight: 500;
  transition: all 0.3s ease;

  &:empty {
    opacity: 0;
  }

  &:not(:empty) {
    opacity: 1;
  }

}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked)~.mdc-checkbox__background {
  border-color: #333334 !important;
}







