@media screen and (min-width: 800px) {
  //general
  .mobile-view {
    display: none;
  }

  .large-view {
    display: block;
  }

  //athlete page
  .field-wrapper .field {
    width: 33%;
  }
}

@media screen and (min-width: 1100px) {
  //athlete page
  .field-wrapper .field {
    width: 25%;
  }
}

//athlete page
.filter-athlete-wrapper.toggled {
  display: none !important;
}

@media screen and (max-width: 799px) {
  //general
  .large-view {
    display: none;
  }

  .field-wrapper .field {
    width: 50%;
  }
}

@media screen and (max-width: 499px) {
  //athlete page
  .field-wrapper .field {
    width: 75%;
    margin-left: 15%;
  }
}
