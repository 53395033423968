table {
  &.table {
    tbody {
      th,
      td {
        vertical-align: middle;

        &.radio {
          width: 50px;
        }
      }
    }
  }

  &.table-bordered {
    th,
    td {
      padding-left: 4px;
    }
  }
}

@media screen and (min-width: 900px) {
  table {
    &.table-bordered {
      th,
      td {
        padding: 4px 10px;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  table {
    &.table-bordered {
      th,
      td {
        padding: 10px 20px;
      }
    }
  }
}

//athletes
th.radio {
  // width: 30px;
}

//results page
.green {
  color: $green;
}

.red {
  color: $red;
}
